import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useTranslation } from 'react-i18next';
import { GET_STOW_RS_ENDPOINT_DETAIL } from '../../../../queries/OpenStowRs/OpenStowRs';
import { graphQLErrorParse } from '../../../../utils/ErrorGraphQLUtils';
import OpenStowRsView from '../../../../components/Hospital/Management/OpenStowRs/OpenStowRsView';

export default ({ openStowRsAccessUuid }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(null);

  const { loading, error, data } = useQuery(
    GET_STOW_RS_ENDPOINT_DETAIL,
    {
      variables: {
        uuid: openStowRsAccessUuid,
      },
    },
  );

  const openStowRs = data && data.openStowRsEndpoint;

  if (error && !serverError) {
    setServerError(graphQLErrorParse(error).replace(/:/g, '.'));
  }

  return <OpenStowRsView openStowRs={openStowRs} loading={loading} error={t(serverError)} />;
};
