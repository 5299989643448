import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import { DeleteTwoTone, FileCopy, NavigateBefore } from '@material-ui/icons';
import Layout from '../../../MenuLayout';
import { Navbar } from '../../../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../../../componentsUI/SectionBar';
import { isEmpty } from '../../../../utils/ObjectUtils';
import Loading from '../../../Common/Loading';
import { AlertUI, AlertWrapperUI } from '../../../../componentsUI/Alert';
import { OpenStowRsDataCard } from './OpenStowRsDataCard';
import { DeleteOpenRsAccessDialog } from './modal/DeleteOpenRsAccessDialog';
import { getOpenStowRsLink } from './utils/OpenStowRsLink';

export default ({ openStowRs, loading, error }) => {
  const [urlCopied, setUrlCopied] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hospital = useSelector((state) => state.hospital);

  const openStowRsLink = getOpenStowRsLink(openStowRs);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(openStowRsLink).then(() => {
      if (!urlCopied) {
        setUrlCopied(true);
        setTimeout(setUrlCopied, 5000);
      }
    });
  };

  const handleViewList = () => navigate('/hospital/settings/open-stow-rs');
  const handleOpenDelete = () => setShowDeleteModal(true);
  const handleCloseDelete = ({ status }) => {
    setShowDeleteModal(false);
    if (status === 'deleted') {
      setDisableButtons(true);
      setTimeout(() => {
        setDisableButtons(false);
        handleViewList();
      }, 3500);
    }
  };

  const buttons = [
    { name: 'go.back.to.list', icon: NavigateBefore, handleClick: handleViewList, disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'copy.url', icon: FileCopy, handleClick: handleCopyUrl, disabled: disableButtons },
    { name: 'divider2', type: 'divider' },
    { name: 'remove.endpoint', icon: DeleteTwoTone, I: 'delete', data: hospital, handleClick: handleOpenDelete, disabled: disableButtons },
  ];

  return (
    <Layout type="settings">
      <Navbar>
        <SectionBar title="hospital.settings.open.stow.rs.manage" items={buttons} />
      </Navbar>
      <DeleteOpenRsAccessDialog
        open={!!showDeleteModal}
        onClose={handleCloseDelete}
        openStowRsAccess={openStowRs}
        refetch={() => {}}
      />
      {error && (
        <AlertWrapperUI>
          <AlertUI severity="error" title="Error">
            {error}
          </AlertUI>
        </AlertWrapperUI>
      )}
      {(loading || isEmpty(openStowRs)) && !error && <Loading />}
      {openStowRs && (
        <OpenStowRsDataCard
          openStowRs={openStowRs}
          urlCopied={urlCopied}
          handleCopyUrl={handleCopyUrl}
        />
      )}
    </Layout>
  );
};
