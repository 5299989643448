export const getOpenStowRsLink = (openStowRs) => {
  if (!openStowRs) return '';

  const type = (() => {
    if (openStowRs.teamMember) return 'team.member';
    if (openStowRs.userGroup) return 'user.group';
    return 'admin.only';
  })();

  const link = {
    domain: process.env.GATSBY_CLOUD_API_BASE_URL,
    hospital: openStowRs.hospital.uuid,
    token: openStowRs.token,
    user: openStowRs.teamMember && openStowRs.teamMember.uuid,
    group: openStowRs.userGroup && openStowRs.userGroup.uuid,
  };

  switch (type) {
    case 'user.group':
      return `${link.domain}/stow-rs/hospital/${link.hospital}/group/${link.group}/token/${link.token}/studies`;
    case 'team.member':
      return `${link.domain}/stow-rs/hospital/${link.hospital}/user/${link.user}/token/${link.token}/studies`;
    case 'admin.only':
      return `${link.domain}/stow-rs/hospital/${link.hospital}/token/${link.token}/studies`;
    default:
      return '';
  }
};
