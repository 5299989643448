import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DoneOutlineTwoTone, FileCopyTwoTone } from '@material-ui/icons';


import { ScrollableContainer } from '../../../Common/ScrollableContainer';
import Loading from '../../../Common/Loading';

import useStyles from '../../Edit/styled/HospitalFormMakeStyles';
import { GET_USER_UUID } from '../../../../graphql/queries';
import { graphQLErrorParse } from '../../../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../../../componentsUI/Alert';
import { ButtonUI } from '../../../../componentsUI/Button';
import { getOpenStowRsLink } from './utils/OpenStowRsLink';

const useLocalStyles = makeStyles((theme) => ({
  data: {
    '& .attribute': {
      fontSize: '1.375rem',
    },
    '& .value': {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '& .type': {
      fontWeight: 700,
      color: theme.palette.grey.A400,
    },
    '& .subtitle': {
      fontSize: '1.125rem',
      color: theme.palette.grey.A400,
    },
  },
  url: {
    margin: '-7px 0 -8px',
    padding: 6,
    fontFamily: '"Monaco", monospace',
    fontSize: '1.3rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    background: `${theme.palette.primary.main}15`,
    wordBreak: 'break-all',
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    borderRadius: 8,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}));

export const OpenStowRsDataCard = ({ openStowRs, urlCopied, handleCopyUrl }) => {
  const [serverError, setServerError] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const styles = useLocalStyles();

  const user = useSelector((state) => state.userInterface.user);
  const { loading, error, data } = useQuery(
    GET_USER_UUID,
    {
      variables: {
        uuid: user.uuid,
      },
    },
  );

  if (error && !serverError) {
    setServerError(t(graphQLErrorParse(error)));
  }

  const getTeamMemberUser = (obj) => obj && obj.user && `${obj.user.name} ${obj.user.surname}`;
  const getUserGroupName = (obj) => obj && obj.name;

  const manager = data && data.user;
  const type = (() => {
    if (openStowRs.teamMember) return 'team.member';
    if (openStowRs.userGroup) return 'user.group';
    return 'admin.only';
  })();

  const openStowRsLink = getOpenStowRsLink(openStowRs);

  return (
    <Box className={classes.container}>
      {serverError && (
        <AlertWrapperUI>
          <AlertUI severity="error" title="Error">
            {serverError}
          </AlertUI>
        </AlertWrapperUI>
      )}
      <ScrollableContainer padding="1.25em 0 0">
        <Paper elevation={2} className={classes.wrapper}>
          <Grid container spacing={2}>
            {loading && !manager && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Loading />
                </Grid>
              </Grid>
            )}
            {!!manager && (
              <Grid container spacing={2} className={styles.data}>
                <Grid item xs={12}>
                  <Typography component="span" className="attribute">
                    {`${t('manager')}: `}
                  </Typography>
                  <Typography component="span" className="value">
                    {`${manager.name} ${manager.surname}`}
                  </Typography>
                  <Typography component="div" className="subtitle">
                    {t('user.allowed.to.upload.studies.to.the.stow.rs.endpoint')}
                  </Typography>
                </Grid>
                <Grid item xs={12} />
                {type === 'admin.only' ? (
                  <Grid item xs={12}>
                    <Typography component="span" className="type">
                      {t('restricted.access')}
                    </Typography>
                    <Typography component="div" className="subtitle">
                      {t('private.access.endpoint')}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Typography component="span" className="attribute">
                      {`${t(`shared.with.${type}`)}: `}
                    </Typography>
                    <Typography component="span" className="value">
                      {type === 'team.member' && getTeamMemberUser(openStowRs.teamMember)}
                      {type === 'user.group' && getUserGroupName(openStowRs.userGroup)}
                    </Typography>
                    <Typography component="div" className="subtitle">
                      {type === 'team.member' && t('shared.with.team.member.description')}
                      {type === 'user.group' && t('shared.with.user.group.description')}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <ButtonUI
                    variant={urlCopied ? 'contained' : 'outlined'}
                    color="primary"
                    icon={urlCopied ? <DoneOutlineTwoTone /> : <FileCopyTwoTone />}
                    onClick={handleCopyUrl}
                  >
                    {urlCopied ? t('copied') : t('copy.url')}
                  </ButtonUI>
                </Grid>
                <Grid item xs={12}>
                  <Box className={styles.url}>
                    {openStowRsLink}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </ScrollableContainer>
    </Box>
  );
};
